import ParallaxPageTitle from '../motion/ParallaxPageTitle'
import styled from 'styled-components'

const BigFuckingTitle = styled(ParallaxPageTitle)`
  position: absolute;
  top: 0; 
  left: 0;
  margin-bottom: 5rem;
  text-align: center;
`

export default BigFuckingTitle