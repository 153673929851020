import React from "react";
import {motion} from "framer-motion"
import styled from "styled-components"
import Image from '../../components/styled/Image'
import {fadeIn, extraLong} from '../../styles/animation'
import {primaryTitleFont} from '../../styles/fonts'
import space from '../../styles/space'
import breakpoints from '../../styles/breakpoints'
import SVG from 'react-inlinesvg';
import waves from '../../static/icons/waves.svg'
import circle from '../../static/icons/circle.svg'
import circleFilled from '../../static/icons/circle-filled.svg'
import {connect} from 'react-redux'
import config from '../../config/siteConfig'

const HighlightContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: show;
`

const StyledHighlight = styled(motion.div).attrs({ 
  ...fadeIn, 
  ...extraLong 
})`
  margin-top: ${space.xl}px;
  margin-bottom: ${space.xl}px;
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoints.md} {
    margin-top: ${space.sm}px;
    margin-bottom: 0px;
  }

  ${breakpoints.lg} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

const StyledImage = styled(Image)`
  z-index: 2;
`

const HighlightBody = styled.div`
  background: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].secondaryColor};
  padding: 16px;
  /* max-width: 500px; */
  min-height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
  & > * {
    line-height: 2;
  }
`

const StyledSVG = styled(SVG)`
  position: absolute;
  fill: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].tertiaryFontColor};
  width: 180px;

  z-index: -1;
  top: 25%;
  right: 0;
  transform: translate(50%, -50%);

  > path {
    fill: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].secondaryFontColor};
    fill: currentColor;
  }
` 

const LeftWave = styled(StyledSVG)`
  top: 25%;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 5;
`

const RightWave = styled(StyledSVG)`
  top: 75%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 3;
`

const CircleSVG = styled(StyledSVG)`
  top: 0;
  left: 0;
  transform: translate(-45%, -45%);
  z-index: -1;
  fill: none;
  
  > circle {
    /* 
      fill: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].secondaryFontColor} !important;
      stroke: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].secondaryFontColor} !important;
    */
    stroke: currentColor !important;
  }
`

const HighlightTitle = styled.h1`
  font-family: ${primaryTitleFont};
`

const Highlight = ({children, img, width, title, reduxTheme}) => {
  console.log('reduxTheme', reduxTheme)

  return (
      <StyledHighlight>
        <HighlightBody reduxTheme={reduxTheme} >
          {title && <HighlightTitle>{title}</HighlightTitle>}

          {children}
          <LeftWave src={waves} reduxTheme={reduxTheme} />
        </HighlightBody>
      </StyledHighlight>
  )
}

Highlight.defaultProps = {
  img: config.portrait,
  width: 320,
}

export default connect(({theme}) => ({reduxTheme: theme}))(Highlight)