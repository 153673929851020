import React from "react";
import { Link, graphql } from "gatsby"

import {motion} from "framer-motion"
import styled from "styled-components"
import Container from '../components/styled/Container'
import SectionHeader from '../components/styled/SectionHeader'
import FeaturedProjects from '../components/FeaturedProjects'
import Row from '../components/styled/Row'
import Col from '../components/styled/Col'
import space from '../styles/space'
import breakpoints from '../styles/breakpoints'
import Portrait from '../components/Portrait'
import Highlight from '../components/motion/Highlight'
import Callout from '../components/Callout'
import useMousePosition from "../utils/hooks/useMousePosition";
import { useViewportScroll } from "framer-motion";
import BFT from '../components/styled/BigFuckingTitle'
import {Code} from '../components/styled/Type'

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${space.md}px;

    ${breakpoints.md} {
      margin-top: ${space.xl}px;
    }

    ${breakpoints.lg} {
      margin-top: ${space.xl}px;
    }
  }
`

const IndexPage = ({data}) => {
  const mouse = useMousePosition();
  const scroll = useViewportScroll();
  const projects = data.allMdx.edges.map(({node}) => node);
    
  return (
    <Container>
      <StyledRow center responsive align='center'>
        <Col>
          <BFT center>
            Devan Huapaya
          </BFT>
        </Col>
      </StyledRow>
      

      <StyledRow center responsive align='center'>
        <Col>
          <Row center>
            <Portrait />
          </Row>
        </Col>
      </StyledRow>
      
      <StyledRow center responsive align='center'>
        <Col>
          <h1>
            <Code className="secondaryTitleColor">Full-stack Javascript Developer</Code>. 
            <br/>That builds web and native apps, deploys them to production, and cares about customers.
          </h1>
        </Col>
      </StyledRow>
      <StyledRow center responsive align='center'>
        <Col>
          <SectionHeader>
            Works
          </SectionHeader>
        </Col>
      </StyledRow>
      
      <StyledRow center responsive align='center'>
        <Col>
          <FeaturedProjects mouse={mouse} scroll={scroll} projects={projects} />
        </Col>
      </StyledRow>
    </Container>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            date(formatString: "DD MMMM, YYYY")
            featuredImg
          }
          excerpt
        }
      }
    }
  }
`